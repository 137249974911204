import { FormatMoney } from 'format-money-js';

const fm = new FormatMoney({
  decimals: 2,
  separator: '.',
  decimalPoint: ',',
});

export const priceFormat = (number: number): string => {
  const formatted = fm.from(number);

  if (typeof formatted === 'string') {
    if (formatted.endsWith(',00')) {
      return formatted.slice(0, -3);
    }
    return formatted;
  }

  return '';
};
